import React from "react";
import { INPUT_TYPES } from "../constants/InputTypes";

export function CheckboxAnswer(props) {
  const { question, answerOption, handleChange, currentAnswers } = props;

  return (
    <label
      key={`${question.id}_${answerOption.order}_fl`}
      className="inline-flex items-center"
    >
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 bg-gray-100 border-gray-200 text-gray-400 hover:ring-flowpower-orange hover:ring-opacity-50 focus:ring-flowpower-orange focus:ring-opacity-50"
        value={answerOption.text}
        checked={currentAnswers[answerOption.text]}
        onChange={() => {
          handleChange(
            question.text,
            answerOption.text,
            answerOption.order,
            null,
            INPUT_TYPES.SELECT
          );
        }}
      />
      <span className="ml-2 text-gray-900">{answerOption.text}</span>
    </label>
  );
}

export const OUTCOME_KEYS = {
  COMPARE_A: "compare_a",
  COMPARE_B: "compare_b",
  LESS_URGENT_A: "less_urgent_a",
  LESS_URGENT_B: "less_urgent_b",
  NOW_LOOK_A: "now_look_a",
  NOW_LOOK_B: "now_look_b",
  RELAX_A: "relax_a",
  RELAX_B: "relax_b",
  URGENT_A: "urgent_a",
  URGENT_B: "urgent_b",
  DISQUALIFY_STATE: "disqualify_state",
  DISQUALIFY_SPEND: "disqualify_spend",
};

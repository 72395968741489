//import styles from "./results.module.css";

export function Results(props) {
  const { outcome, handleEnquiryClick } = props;
  const { p1, p2, p3, headline, level, buttonText } = outcome;

  //const { urgent, relax, nowLook, iconUrgent, iconRelax, iconNowLook } = styles;
  let selectedHeadlineStyle =
    "text-xl font-bold font-roboto text-flowpower-orange";
  let svgClass = "h-16 w-16 text-flowpower-orange";

  switch (level) {
    case "urgent":
      selectedHeadlineStyle = "text-xl font-bold font-roboto text-red-600";
      svgClass = "h-16 w-16 text-red-600";
      break;
    case "relax":
      selectedHeadlineStyle = "text-xl font-bold font-roboto text-green-600";
      svgClass = "h-16 w-16 text-green-600";
      break;
    default:
      break;
  }

  return (
    <>
      <div className="bg-white rounded-bl-md rounded-br-md p-2">
        <div className="flex flex-col justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 124 124"
            fill="currentColor"
            stroke="currentColor"
            className={svgClass}
          >
            <defs>
              <clipPath id="clip-path">
                <path d="M0 0H124V124H0z" data-name="Rectangle 24"></path>
              </clipPath>
            </defs>
            <g data-name="Group 100" transform="translate(32.459 -384.541)">
              <g
                clipPath="url(#clip-path)"
                data-name="Group 56"
                transform="translate(-32.459 384.541)"
              >
                <path
                  d="M129.582 170.165a40.582 40.582 0 1140.582-40.582 40.628 40.628 0 01-40.582 40.582m0-76.527a35.944 35.944 0 1035.944 35.944 35.985 35.985 0 00-35.944-35.944"
                  data-name="Path 13"
                  transform="translate(-67.593 -67.304)"
                ></path>
                <path
                  d="M256.465 13.218a2.319 2.319 0 01-2.319-2.319v-8.58a2.319 2.319 0 114.638 0V10.9a2.319 2.319 0 01-2.319 2.319"
                  data-name="Path 14"
                  transform="translate(-194.442 1.057)"
                ></path>
                <path
                  d="M82.884 85a2.311 2.311 0 01-1.64-.679l-6.067-6.071a2.319 2.319 0 013.28-3.28l6.067 6.067A2.319 2.319 0 0182.884 85"
                  data-name="Path 15"
                  transform="translate(-56.455 -56.006)"
                ></path>
                <path
                  d="M10.9 258.493H2.319a2.319 2.319 0 110-4.638H10.9a2.319 2.319 0 110 4.638"
                  data-name="Path 16"
                  transform="translate(.767 -193.929)"
                ></path>
                <path
                  d="M76.611 418.044a2.319 2.319 0 01-1.64-3.959l6.067-6.067a2.319 2.319 0 013.28 3.28l-6.067 6.067a2.312 2.312 0 01-1.64.679"
                  data-name="Path 17"
                  transform="translate(-56.296 -311.82)"
                ></path>
                <path
                  d="M256.173 484.218a2.319 2.319 0 01-2.319-2.319v-8.58a2.319 2.319 0 014.638 0v8.58a2.319 2.319 0 01-2.319 2.319"
                  data-name="Path 18"
                  transform="translate(-194.218 -360.718)"
                ></path>
                <path
                  d="M415.725 418.25a2.311 2.311 0 01-1.64-.679l-6.067-6.067a2.319 2.319 0 013.28-3.28l6.067 6.067a2.319 2.319 0 01-1.64 3.959"
                  data-name="Path 19"
                  transform="translate(-312.11 -311.979)"
                ></path>
                <path
                  d="M481.9 258.784h-8.58a2.319 2.319 0 110-4.638h8.58a2.319 2.319 0 110 4.638"
                  data-name="Path 20"
                  transform="translate(-361.008 -194.153)"
                ></path>
                <path
                  d="M409.864 85.2a2.319 2.319 0 01-1.64-3.959l6.067-6.067a2.319 2.319 0 013.28 3.28l-6.071 6.071a2.312 2.312 0 01-1.64.679"
                  data-name="Path 21"
                  transform="translate(-312.268 -56.165)"
                ></path>
                <path
                  d="M213.074 229.138a3.073 3.073 0 01-1.628-.471 3.037 3.037 0 01-1.252-3.676l5.159-13.808h-3.729a3.066 3.066 0 01-2.863-4.164l6.454-16.826a3.084 3.084 0 012.863-1.968h15.092a3.066 3.066 0 012.625 4.65l-6.008 9.96h2.279A3.066 3.066 0 01234.3 208l-19 20.163a3.046 3.046 0 01-2.223.971m.836-22.593h3.708a3.066 3.066 0 012.872 4.139l-3.225 8.631 11.159-11.842H227a3.066 3.066 0 01-2.625-4.649l6.008-9.96h-11.226z"
                  data-name="Path 22"
                  transform="translate(-159.425 -143.519)"
                ></path>
              </g>
            </g>
          </svg>
          <span className={selectedHeadlineStyle}>{headline}</span>
        </div>
        <div className="flex flex-col-reverse lg:flex-col justify-center items-center">
          <article className="prose prose-md text-center font-roboto px-4 lg:pt-4">
            <p>{p1}</p>
            <p>{p2}</p>
            {p3 && <p>{p3}</p>}
          </article>
          <div className="p-4">
            <span className="relative inline-flex rounded-md shadow-sm">
              <button
                onClick={() => handleEnquiryClick()}
                className="
                inline-flex 
                items-center 
                px-4 
                py-2 
                border
                bg-flowpower-orange 
                rounded-md s
                text-md
                text-white 
                font-roboto 
                hover:ring-4 
                hover:ring-flowpower-orange 
                hover:ring-opacity-50"
              >
                {buttonText}
              </button>
              <span className="flex absolute h-3 w-3 top-0 right-0 -mt-1 -mr-1">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-green-600 "></span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

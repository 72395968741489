import React, { useState } from "react";
import styles from "./DropdownAnswer.module.css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { INPUT_TYPES } from "../constants/InputTypes";

export function DropdownAnswer(props) {
  const { question, answerOption, handleChange, currentAnswers } = props;
  const [sites, setSites] = useState(
    currentAnswers[answerOption.text] || "Please Select"
  );

  const generateOption = () => {
    let items = [];
    items.push(
      <MenuItem
        key={"default"}
        value={"Please Select"}
      >{`Please Select`}</MenuItem>
    );

    for (let index = 1; index < 201; index++) {
      items.push(
        <MenuItem key={index} value={index}>
          {index}
        </MenuItem>
      );
    }
    items.push(<MenuItem key={201} value={201}>{`200+`}</MenuItem>);
    return items;
  };
  return (
    <FormControl key={1} className={styles.formControl}>
      <Select
        id="demo-simple-select"
        value={sites}
        onChange={(e) => {
          handleChange(
            question.text,
            answerOption.text,
            answerOption.order,
            e.target.value,
            INPUT_TYPES.DROPDOWN
          );
          setSites(e.target.value);
        }}
      >
        {generateOption().map((i) => i)}
      </Select>
    </FormControl>
  );
}

// App imports
import React, { useState, useEffect } from "react";
import "./App.css";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Firebase React Hooks
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useForm } from "react-hooks-helper";

// Constants
import { SECTION_PROGRESS } from "./constants/SectionProgress";
import { OUTCOME_KEYS } from "./constants/OutcomesKeys";

// Components
import { AboutYou } from "./components/about-you";
import { Question } from "./components/question";
import { Results } from "./components/results";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { INPUT_TYPES } from "./constants/InputTypes";

// Check if firebase has been initialised. There can be issues if the site tries to re-initialise firebase
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyDTanx8QPKlyeKCqZ2n-lR2-ISg0A0O85s",
    authDomain: "flowpower-dev.firebaseapp.com",
    projectId: "flowpower-dev",
    storageBucket: "flowpower-dev.appspot.com",
    messagingSenderId: "400346452112",
    appId: "1:400346452112:web:1930c8985a55a4b2690815",
  });
} else {
  firebase.app(); // if already initialized, use that one
}

// Reference to DB
const firestore = firebase.firestore();

// DTO for about you (form) data
const aboutYouData = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  companyName: "",
  jobTitle: "",
};

function App() {
  // State vars
  const [progress, setProgress] = useState(SECTION_PROGRESS.SURVEY);
  const [aboutYouFormData, setAboutYouFormData] = useForm(aboutYouData);
  // eslint-disable-next-line
  const [account, setAccount] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  // questionAnswers gets initialised after questionsCollection has been retrieved
  const [questionAnswers, setQuestionAnswers] = useState(null);
  // outcome gets initialised after questions have been answered
  const [outcome, setOutcome] = useState(null);

  const [answersId, setAnswersId] = useState(null);

  // Firestore
  // -- Questions
  const questionsRef = firestore.collection("questions");
  const questionsQuery = questionsRef.orderBy("order").limit(25);
  // eslint-disable-next-line no-unused-vars
  const [questionsCollection, loading] = useCollectionData(questionsQuery, {
    idField: "id",
  });

  const outcomesRef = firestore.collection("outcomes");
  const [outcomes] = useCollectionData(outcomesRef, {
    idField: "id",
  });

  useEffect(() => {
    // initialise questionAnswers
    if (questionsCollection && questionAnswers === null) {
      let newQuestionAnswers = [];
      questionsCollection.forEach((question) => {
        let questionAnswers = {};

        question.options.forEach((option) => {
          questionAnswers[option.text] = false;
        });

        questionAnswers.questionText = question.text;
        questionAnswers.selectedOrder = null;

        newQuestionAnswers.push(questionAnswers);
      });

      setQuestionAnswers(newQuestionAnswers);
    }
    //only updates when one of these change questionsCollection, questionAnswers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsCollection, questionAnswers]);

  // -- Answers
  const answersRef = firestore.collection("answers");

  const calculateResults = () => {
    let outcomeKey = OUTCOME_KEYS.URGENT_A;

    // Time to contact end
    const { selectedOrder: contractEnd } = questionAnswers[3];

    switch (contractEnd) {
      case "a":
        outcomeKey = OUTCOME_KEYS.URGENT_A;
        break;
      case "b":
        outcomeKey = OUTCOME_KEYS.URGENT_B;
        break;
      case "c":
        outcomeKey = OUTCOME_KEYS.NOW_LOOK_A;
        break;
      case "d":
        outcomeKey = OUTCOME_KEYS.NOW_LOOK_B;
        break;
      case "e":
        outcomeKey = OUTCOME_KEYS.RELAX_A;
        break;

      default:
        outcomeKey = OUTCOME_KEYS.URGENT_A;
        break;
    }

    // Spend disqualification
    const { selectedOrder: spendPeriod } = questionAnswers[4];
    const { selectedOrder: spendAmount } = questionAnswers[5];

    if (
      (spendPeriod === "a" && spendAmount === "a") ||
      (spendPeriod === "b" && ["a", "b"].includes(spendAmount))
    ) {
      outcomeKey = OUTCOME_KEYS.DISQUALIFY_SPEND;
    }

    // State disqualification - If state is only WA or NT, the disqualify
    const states = questionAnswers[2];
    const selectedStates = Object.keys(states).filter(
      (k) => states[k] === true
    );

    if (selectedStates.includes("NT") || selectedStates.includes("WA")) {
      // If only one selected, we know to disqualify
      if (selectedStates.length === 1) {
        outcomeKey = OUTCOME_KEYS.DISQUALIFY_STATE;
      }

      if (selectedStates.length === 2) {
        if (selectedStates.includes("NT") && selectedStates.includes("WA")) {
          outcomeKey = OUTCOME_KEYS.DISQUALIFY_STATE;
        }
      }
    }

    // when outcomes have loaded
    if (outcomes) {
      const outcomeData = outcomes.find((outcome) => outcome.id === outcomeKey);
      setOutcome(outcomeData);
    }

    return outcomeKey;
  };

  // handles the slight possibility that the user gets to the results page before results have loaded
  useEffect(() => {
    // when on the servery page and outcomes have now loaded and outcome has not been set
    if (outcomes && outcome === null && progress === SECTION_PROGRESS.SURVEY) {
      calculateResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomes, outcome, progress]);

  // Button handlers
  const handleNextQuestion = async () => {
    const nextQuestion = currentQuestion + 1;

    // First question
    if (!answersId) {
      const answerModel = {
        account: {},
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        answers: questionAnswers,
        outcomeKey: "",
        partial: true,
        synced: false,
      };

      let docRef = await answersRef.add(answerModel);
      setAnswersId(docRef.id);
    } else {
      // After the first question - i.e we have saved info in Firebase
      await answersRef.doc(answersId).update({
        answers: questionAnswers,
      });
    }

    if (nextQuestion >= questionsCollection.length) {
      let outcome = calculateResults();
      await answersRef.doc(answersId).update({
        partial: false,
        outcomeKey: outcome,
      });
      setProgress(SECTION_PROGRESS.RESULTS);
    } else {
      setCurrentQuestion(nextQuestion);
    }
  };

  const handlePrevQuestion = () => {
    const prevQuestion = currentQuestion - 1;
    if (prevQuestion < 0) {
      setProgress(SECTION_PROGRESS.ABOUT);
    } else if (progress === SECTION_PROGRESS.RESULTS) {
      setProgress(SECTION_PROGRESS.SURVEY);
    } else {
      setCurrentQuestion(prevQuestion);
    }
  };

  const handleAnswerSelect = (
    questionText,
    text,
    order,
    inputValue = null,
    inputType
  ) => {
    const newQuestionAnswers = [...questionAnswers];

    if (inputType === INPUT_TYPES.SELECT) {
      if (!questionsCollection[currentQuestion].allowMultiple) {
        // if question doesn't allow multiple then set all the answers to false first
        Object.keys(newQuestionAnswers[currentQuestion]).forEach(
          (key, index) => {
            // as these are not questions skip them
            if (key !== "selectedOrder" && key !== "questionText") {
              newQuestionAnswers[currentQuestion][key] = false;
            }
          }
        );
      }
      newQuestionAnswers[currentQuestion][text] = !newQuestionAnswers[
        currentQuestion
      ][text];
    }

    if (inputType === INPUT_TYPES.FREE_TEXT) {
      newQuestionAnswers[currentQuestion][text] = inputValue;
    }

    if (inputType === INPUT_TYPES.DROPDOWN) {
      newQuestionAnswers[currentQuestion][text] = inputValue;
    }

    newQuestionAnswers[currentQuestion].selectedOrder = order;

    setQuestionAnswers(newQuestionAnswers);
  };

  const handleAboutYou = async () => {
    // End of survey. Save answers and calculate results

    // Calculate Results
    const outcomeKey = calculateResults();

    // Construst the answers model
    // const answerModel = {
    //   account: aboutYouFormData,
    //   createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    //   answers: questionAnswers,
    //   outcomeKey: outcomeKey,
    //   synced: false,
    // };

    // Save to firebase
    await answersRef.doc(answersId).update({
      account: aboutYouFormData,
      outcomeKey: outcomeKey,
    });

    // Show results page
    window.location.href =
      "https://power.flowpower.com.au/business-energy-benchmark-thankyou/";
  };

  const shouldDisableButton = () => {
    const currentAnswer = questionAnswers[currentQuestion];
    return currentAnswer.selectedOrder === null;
  };

  const shouldDisableSubmit = () => {
    let disable = false;
    Object.keys(aboutYouFormData).forEach((x) => {
      if (aboutYouFormData[x] === null || aboutYouFormData[x] === "") {
        disable = true;
      }
    });
    return disable;
  };

  const handleEnquiryClick = () => {
    setProgress(SECTION_PROGRESS.ABOUT);
  };

  return (
    <>
      {progress !== SECTION_PROGRESS.START &&
        (!questionAnswers ? (
          <div className="bg-image bg-cover bg-center bg-no-repeat">
            <div className="min-h-screen flex flex-col">
              <Header />
              <div className="flex flex-col flex-1 lg:mt-0 lg:w-1/2 lg:mx-auto">
                <main className="m-5 lg:m-0 lg:mt-3">
                  <div className="bg-flowpower-orange flex justify-center items-center text-center text-white text-lg font-roboto font-bold rounded-tl-md rounded-tr-md h-16">
                    <h1>Loading...</h1>
                  </div>
                  <div className="bg-white rounded-bl-md rounded-br-md h-72 flex items-center justify-center font-mulish text-lg">
                    <h1>Benchmark your business electricity</h1>
                  </div>
                </main>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-image bg-cover bg-center bg-no-repeat">
            <div className="min-h-screen flex flex-col">
              <Header />
              <div className="flex flex-col flex-1 lg:mt-0 lg:w-1/2 lg:mx-auto">
                {
                  // Main Content
                }
                <main className="m-5 lg:m-0">
                  <div className="bg-flowpower-orange flex justify-center items-center text-center text-white text-md font-roboto font-bold rounded-tl-md rounded-tr-md h-12">
                    <h3>
                      {progress === SECTION_PROGRESS.ABOUT && "About You"}
                      {progress === SECTION_PROGRESS.RESULTS && "The Results"}
                      {progress === SECTION_PROGRESS.SURVEY &&
                        (questionsCollection[currentQuestion].text || "Survey")}
                    </h3>
                  </div>

                  {progress === SECTION_PROGRESS.ABOUT && (
                    <div className="account-form-wrapper">
                      <AboutYou
                        formData={aboutYouFormData}
                        setValue={setAboutYouFormData}
                      />
                    </div>
                  )}

                  {questionsCollection &&
                    progress === SECTION_PROGRESS.SURVEY && (
                      <Question
                        currentAnswers={questionAnswers[currentQuestion]}
                        question={questionsCollection[currentQuestion]}
                        handleChange={handleAnswerSelect}
                      />
                    )}

                  {progress === SECTION_PROGRESS.RESULTS && outcome && (
                    <Results
                      outcome={outcome}
                      handleEnquiryClick={handleEnquiryClick}
                    />
                  )}
                </main>
                {
                  // END Main Content
                }
                {
                  //Button Section
                }
                <nav className="flex flex-row justify-around m-5 lg:m-0 lg:mt-3 text-white">
                  {progress === SECTION_PROGRESS.SURVEY &&
                    currentQuestion !== 0 && (
                      <button
                        onClick={() => handlePrevQuestion()}
                        className="bg-flowpower-orange p-2 w-36 h-11 lg:h-10 rounded-md font-roboto font-semibold lg:text-sm hover:ring-4 hover:ring-flowpower-orange hover:ring-opacity-50"
                      >
                        Previous
                      </button>
                    )}

                  {progress === SECTION_PROGRESS.SURVEY && (
                    <button
                      onClick={() => handleNextQuestion()}
                      className="bg-flowpower-orange p-2 w-36 h-11 lg:h-10 rounded-md font-roboto font-semibold lg:text-sm hover:ring-4 hover:ring-flowpower-orange hover:ring-opacity-50"
                      disabled={shouldDisableButton()}
                    >
                      Next
                    </button>
                  )}

                  {progress === SECTION_PROGRESS.ABOUT && (
                    <button
                      onClick={() => handleAboutYou()}
                      className="bg-flowpower-orange p-2 w-36 h-11 lg:h-10 rounded-md font-roboto font-semibold lg:text-sm hover:ring-4 hover:ring-flowpower-orange hover:ring-opacity-50"
                      disabled={shouldDisableSubmit()}
                    >
                      Find Out More
                    </button>
                  )}
                </nav>
                {
                  // END Button Section
                }
              </div>
            </div>
            <Footer />
          </div>
        ))}
    </>
  );
}

export default App;

// Material Components
import FormGroup from "@material-ui/core/FormGroup";
//import { makeStyles } from "@material-ui/core/styles";
import { CheckboxAnswer } from "./CheckboxAnswer";
import { DropdownAnswer } from "./DropdownAnswer";
import { INPUT_TYPES } from "../constants/InputTypes";

export function Question(props) {
  const { currentAnswers, question, handleChange } = props;
  const answerOptions = question.options ?? [];
  const questionsToDisplay = answerOptions.sort((a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <div className="bg-white rounded-bl-md rounded-br-md">
        <div className="text-gray-600 text-center font-mulish text-md border-b-2 pt-1 pb-1">
          {question.allowMultiple ? "Select all that apply" : "Select one"}
        </div>

        <div className="text-gray-900 font-semibold text-sm">
          <FormGroup className="p-5 space-y-4 flex flex-col font-mulish">
            {questionsToDisplay.map((answerOption, index) =>
              answerOption.inputType === INPUT_TYPES.FREE_TEXT ? (
                <DropdownAnswer
                  question={question}
                  answerOption={answerOption}
                  handleChange={handleChange}
                  currentAnswers={currentAnswers}
                  key={`${question.id}_${answerOption.order}_fta`}
                />
              ) : (
                <CheckboxAnswer
                  question={question}
                  answerOption={answerOption}
                  handleChange={handleChange}
                  currentAnswers={currentAnswers}
                  key={`${question.id}_${answerOption.order}_cba`}
                />
              )
            )}
          </FormGroup>
        </div>
      </div>
    </>
  );
}

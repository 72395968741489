import React from "react";

export default function Footer() {
  return (
    <footer className="h-16 text-center text-white text-sm">
      <a
        href="https://justclickhere.com.au/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-flowpower-orange"
      >
        Privacy Policy
      </a>
      <p>Copyright © 2021 Flow Power. All Rights Reserved.</p>
    </footer>
  );
}

import React from "react";
import logo from "../images/logo.png";

export default function Header() {
  return (
    <div className="flex flex-row flex-none h-24 justify-between align-center items-center p-5 lg:p-0 lg:w-1/2 lg:mx-auto">
      <div>
        <a href="/">
          <img src={logo} alt="" className="w-24" />
        </a>
      </div>
      <div className="text-white text-lg font-semibold font-roboto text-right lg:w-auto ">
        <p>
          Benchmark Your <br className="block lg:hidden" /> Business Electricity
        </p>
      </div>
    </div>
  );
}

export function AboutYou(props) {
  const { formData, setValue } = props;

  return (
    <form className="bg-white rounded-bl-md rounded-br-md">
      <div className="flex flex-col items-center justify-center p-6 ">
        <div className="relative text-gray-600 focus-within:text-gray-400 w-full lg:w-2/3">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6 text-flowpower-orange ml-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            required
            value={formData.firstName}
            onChange={setValue}
            className="border-0 border-gray-400 border-b-2 pl-8 m-2 font-mulish font-semibold focus:outline-none focus:border-orange-400 w-full"
          />
        </div>

        <div className="relative text-gray-600 focus-within:text-gray-400 w-full lg:w-2/3">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 text-flowpower-orange ml-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            required
            value={formData.lastName}
            onChange={setValue}
            className="border-0 border-gray-400 pl-8 border-b-2 m-2 font-mulish font-semibold focus:outline-none focus:border-orange-400 w-full"
          />
        </div>

        <div className="relative text-gray-600 focus-within:text-gray-400 w-full lg:w-2/3">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 text-flowpower-orange ml-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
              />
            </svg>
          </span>
          <input
            type="text"
            name="phoneNumber"
            placeholder="Mobile Number"
            required
            value={formData.phoneNumber}
            onChange={setValue}
            className="border-0 border-gray-400 pl-8 border-b-2 m-2 font-mulish font-semibold focus:outline-none focus:border-orange-400 w-full"
          />
        </div>

        <div className="relative text-gray-600 focus-within:text-gray-400 w-full lg:w-2/3">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 text-flowpower-orange ml-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
              />
            </svg>
          </span>
          <input
            type="email"
            name="email"
            placeholder="Email"
            required
            value={formData.email}
            onChange={setValue}
            className="border-0 border-gray-400 pl-8 border-b-2 m-2 font-mulish font-semibold focus:outline-none focus:border-orange-400 w-full"
          />
        </div>

        <div className="relative text-gray-600 focus-within:text-gray-400 w-full lg:w-2/3">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 text-flowpower-orange ml-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
              />
            </svg>
          </span>
          <input
            type="text"
            name="companyName"
            placeholder="Company Name"
            required
            value={formData.companyName}
            onChange={setValue}
            className="border-0 border-gray-400 pl-8 border-b-2 m-2 font-mulish font-semibold focus:outline-none focus:border-orange-400 w-full"
          />
        </div>
        <div className="relative text-gray-600 focus-within:text-gray-400 w-full lg:w-2/3">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 text-flowpower-orange ml-1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
          </span>
          <input
            type="text"
            name="jobTitle"
            placeholder="Job Title"
            required
            value={formData.jobTitle}
            onChange={setValue}
            className="border-0 border-gray-400 pl-8 border-b-2 m-2 font-mulish font-semibold focus:outline-none focus:border-orange-400 w-full"
          />
        </div>
      </div>
    </form>
  );
}
